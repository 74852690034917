<template>
    <div class="BigBox">
        <div class="PersonalCenter">
            <div class="Header">
                <div style="margin-left: 3vw;display: flex;align-items: center;cursor: pointer; " @click="DjFan()">
                    <img src="../../assets/PersonalCenter/logo.png" style="width:10vw;height:7vh;">
                </div>
                <div style="color:rgba(255, 255, 255, 1);font-size:3vh;margin-left:6vh;margin-top:3vh;">个人中心</div>
                <div class="HeaderMessage">
                    <!-- <div style="width: 2vw;height: 4vh;margin-right: 2vw;">
                        <img src="../../assets/PersonalCenter/xing/MessageloggingOne.png" alt="">
                    </div> -->
                    <div style="width: 2vw;height: 2vw;margin: 0 1vw;">
                        <img :src="UserList.iconPath" style="width: 100%;height: 100%;border-radius:50%;" v-if="ImgOne">
                        <img src="../../assets/PersonalCenter/HeadPortrait.png" style="width: 100%;height: 100%;" v-else>
                    </div>
                    <div>
                        <p style="color: #FFFFFF;font-size: 1.2vw;">{{ UserList.mobile }}</p>
                    </div>
                </div>
            </div>
            <div class="Content">
                <!-- 导航栏 -->
                <div class="NavigationBar">
                    <!-- 头像 -->
                    <div class="HeadPortrait">
                        <img :src="UserList.iconPath" style="width: 100%;height: 100%;border-radius:50%;" v-if="ImgOne">
                        <img src="../../assets/PersonalCenter/HeadPortrait.png" style="width: 100%;height: 100%;" v-else>
                    </div>
                    <!-- 功能栏 -->
                    <div class="FunctionBar">
                        <div @click="home" class="home" :class="{home:details.deviceId == 0,HomeOne:details.deviceId == 1,}">
                            <img src="../../assets/PersonalCenter/xing/WorkOne.png" alt="">
                            <p>工作台</p>
                        </div>
                        <div @click="personalInformation" class="personalInformation">
                            <img src="../../assets/PersonalCenter/xing/PersonalOne.png" alt="" class="PerImg">
                            <p>个人资料</p>
                        </div>
                        <div @click="myOrder" class="myOrder">
                            <img src="../../assets/PersonalCenter/xing/OrderOne.png" alt="">
                            <p>我的订单</p>
                        </div>
                        <!-- <div @click="shoppingCart" class="shoppingCart">
                            <img src="../../assets/PersonalCenter/icon/consume.png" alt="">
                            <p>购物车</p>
                        </div> -->
                        <div @click="myAssessment" class="myAssessment">
                            <img src="../../assets/PersonalCenter/xing/EvaluateOne.png" alt="">
                            <p>我的评价</p>
                        </div>
                        <!-- <div @click="collect" class="collect">
                            <img src="../../assets/PersonalCenter/collect/collect.png" alt="">
                            <p>征集管理</p>
                        </div> -->
                        <div @click="colRest" class="colRest" :class="{colRest:details.deviceId == 0,ColRestOne:details.deviceId == 1,}">
                            <img src="../../assets/PersonalCenter/xing/MoreOne.png" alt="">
                            <p>其他服务</p>
                        </div>
                    </div>
                    <!-- 按钮 -->
                    <div class="Buttons">
                        <div @click="GoLogin">切换账户</div>
                        <div @click="GoLogin">退出登录</div>
                    </div>
                </div>
                <!-- 主页面 -->
                <div class="Show" v-if="mainPage">
                    <!-- 公告 -->
                    <div class="ShowTop">
                        <!-- 古镇群 -->
                        <div class="AncientTown">
                            <div>
                                <img @click="GoTGAncientTown" src="../../assets/PersonalCenter/CopperOfficer.png" alt="">
                            </div>
                            <div>
                                <img @click="GoJGAncientTown" src="../../assets/PersonalCenter/Jinggang.png" alt="">
                            </div>
                            <div>
                                <img @click="GoSTSAncientTown" src="../../assets/PersonalCenter/BookHall.png" alt="">
                            </div>
                            <div>
                                <img @click="GoQKAncientTown" src="../../assets/PersonalCenter/JoeMouth.png" alt="">
                            </div>
                            <div>
                                <img @click="GoXKAncientTown" src="../../assets/PersonalCenter/purchase.png" alt="">
                            </div>
                        </div>
                    </div>
                    <!-- 工作台 -->
                    <div class="ShowBottom">
                        <!-- 分类-->
                        <div class="Law-Tab">
                            <div class="Law-Tab-Nav">
                                <div v-for="(item, index) in NavList" :key="index" :class="{ btna: count == index }"
                                    @click="change(index)" class="li">
                                    <div style="width:100%;height: 100%;display: flex;justify-content: center;align-items: center;border-top-right-radius:1vh; border-bottom-right-radius:1vh; /* 右下角 */"
                                        :class="color[index]">
                                        <div :class="{ actived: active == index }" class="Zt">{{ item }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 一级分类 -->
                        <div class="end-cont" :class="{ dis: btnnum == 0 }">
                            <!-- 分类-->
                            <div class="Law-TabOne">
                                <div class="Law-Tab-NavOne">
                                    <div v-for="(item, index) in NavListOne" :key="index"
                                        :class="{ BtnaTher: CountTher == index }"
                                        @click="ChangeTher(index), ChangeFive(index)" class="LiOne">

                                        <div :class="{ ActivedTher: ActiveTher == index }" class="Zt">{{ item }}</div>

                                    </div>
                                </div>
                            </div>
                            <!-- 点赞分类内容 -->
                            <!-- <div v-show="0 === number" class="perNumber" > v-if="Bottom" v-if="Bottom"-->
                            <div class="End-contTher" :class="{ DisTher: BtnnumTher == 0 }" >
                                <!-- 边框 -->
                                <div class="PerBox" v-if="Bottom">
                                    <!-- 标题 -->
                                    <!-- <div class="PerTitle">景区空间</div> -->
                                    <!-- 里面的边框 -->
                                    <div>
                                        <div class="PerBoxOne" @scroll="handleScroll($event)" ref="personDom">
                                            <div style="width:18%;height:25vh;  margin-left:2vh;"
                                                v-for="(item, index) in ListOne " :key="index">
                                                <!-- 图片 -->
                                                <div class="PerPicture">
                                                    <div class="PerPictureOne" v-on:mouseenter="showDialog($event)"
                                                        v-on:mouseleave="hideDialog($event)" :style="{ 'backgroundImage': 'url(' + item.logoUrl + ')' }">
                                                        <!-- 删除 v-if="delete"-->
                                                        <div class="PerDelete" v-show="isShow"> <img
                                                                src="../../assets/PersonalCenter/DeleteOne.png" alt=""
                                                                style="width:70%;height:70%;" class="img"> </div>
                                                    </div>
                                                </div>
                                                <!-- 二级标题 -->
                                                <div style="font-size:1.7vh;margin-top:0.5vh;">{{ item.objectName }}</div>
                                                <!-- 作者 -->
                                                <div
                                                    style="font-size:1.6vh;color: rgba(140, 140, 140, 1);margin-top:0.5vh;">
                                                    优秀创作</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                  <!-- 暂无数据 v-if="Data"-->
                            <div class="ShowData" v-if="Data">
                                <div class="ShowPicture"> <img src="../../assets/PersonalCenter/NoLikes.png" alt=""> </div>
                                <div
                                    style="margin-left:-12vh; margin-top:5vh; color:rgba(221, 221, 221, 1);font-size:3vh;font-weight:200;">
                                    暂无喜欢</div>
                                <div style="color: rgba(221, 221, 221, 1);font-size:2vh;margin-left:-12vh;margin-top:2vh;">
                                    快去去添加喜欢吧!</div>
                            </div>
                            </div>
                          
                        </div>
                        <!-- 收藏列表 -->
                        <div class="end-cont" :class="{ dis: btnnum == 1 }" >
                            <!-- 分类 v-if="BottomOne"-->
                            <div class="Law-TabTwo">
                                <div class="Law-Tab-NavTwo">
                                    <div v-for="(item, index) in NavListTwo" :key="index"
                                        :class="{ BtnaFour: CountFour == index }"
                                        @click="ChangeFour(index), ChangeFix(index)" class="LiOne">

                                        <div :class="{ ActivedFour: ActiveFour == index }" class="Zt">{{ item }}</div>

                                    </div>
                                </div>
                            </div>
                            <!-- 收藏分类内容 v-if="BottomOne"-->
                            <div class="End-contFour" :class="{ DisFour: BtnnumFour == 1 }" >
                                <!-- 边框 -->
                                <div class="PerBox" v-if="BottomOne">
                                    <!-- 标题 -->
                                    <!-- <div class="PerTitle">景区空间</div> -->
                                    <!-- 里面的边框 -->
                                    <div >
                                        <div class="PerBoxOne" @mousewheel="HandleScrollOne($event)" ref="PersonDomOne" >
                                            <div style="width:18%;height:25vh;margin-left:2vh;"
                                                v-for="(item, index) in ListTwo " :key="index">
                                                <!-- 图片 -->
                                                <div class="PerPicture">
                                                    <div class="PerPictureOne" v-on:mouseenter="showDialog($event)"
                                                        v-on:mouseleave="hideDialog($event)" :style="{ 'backgroundImage': 'url(' + item.logoUrl + ')' }">
                                                        <!-- 删除 v-if="delete"-->
                                                        <div class="PerDelete" v-show="isShow"> <img
                                                                src="../../assets/PersonalCenter/DeleteOne.png" alt=""
                                                                style="width:70%;height:70%;" class="img"> </div>
                                                    </div>
                                                </div>
                                                <!-- 二级标题 -->
                                                <div style="font-size:1.7vh;margin-top:0.5vh;">{{ item.objectName }}</div>
                                                <!-- 作者 -->
                                                <div
                                                    style="font-size:1.6vh;color: rgba(140, 140, 140, 1);margin-top:0.5vh;">
                                                    优秀创作</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 暂无数据 v-if="DataOne"-->
                                    <!-- 暂无数据  v-if="DataOne" -->
                            <div class="ShowData" v-if="DataOne">
                                <div class="ShowPicture"> <img src="../../assets/HomePage/enshrine.png" alt=""> </div>
                                <div
                                    style="margin-left:-12vh; margin-top:5vh; color:rgba(221, 221, 221, 1);font-size:3vh;font-weight:200;">
                                    暂无收藏</div>
                                <div style="color: rgba(221, 221, 221, 1);font-size:2vh;margin-left:-12vh;margin-top:2vh;">
                                    快去加入收藏吧！</div>
                            </div>
                            </div>
                        
                        </div>
                        <div class="end-cont" :class="{ dis: btnnum == 2 }">
                            <div @click="feedback()"
                                style="width:7.5%;height:4.5vh;background-color:rgba(100, 162, 255, 1);color:rgba(255, 255, 255, 1);font-size:2vh;display: flex;justify-content:center;align-items: center;border-radius:0.5vh;float:right;cursor: pointer;margin-right:3vw;">
                                确认</div>
                            <div class="PerBoxTwo">

                                <!-- 输入 -->
                                <div class="PerBoxTher">
                                    <el-row class="PerCons">
                                        <!-- 昵称 -->
                                        <el-form :rules="rules" :model="ruleForm" ref="ruleForm" class="PerRor">
                                            <div style="width:6.5%;margin-left:1vw;font-size:2vh;">姓名:</div>
                                            <el-form-item prop="name" style="width:85%;height:100%;">
                                                <el-input v-model="ruleForm.name" clearable></el-input>
                                            </el-form-item>
                                        </el-form>
                                        <!-- 空白 -->
                                        <!-- <div style="width: 100%;height:6.5vh;border: 1px solid red;"></div> -->
                                        <!-- 电话号码 -->
                                        <el-form :rules="rules" :model="ruleForm" ref="ruleForm" class="PerRor">
                                            <div style="width:12.2%;margin-left:1vw;font-size:2vh;">电话号码:</div>
                                            <el-form-item prop="tel" style="width:85%;height:100%;">
                                                <el-input v-model="ruleForm.tel" clearable
                                                    oninput="if(value.length > 11)value = value.slice(0, 11)"></el-input>
                                            </el-form-item>
                                        </el-form>
                                    </el-row>
                                    <!-- 上传 -->
                                    <div class="PerBoxFour">
                                        <!-- 标题 -->
                                        <div style="font-size:2vh;">上传图片：</div>
                                        <div style="width:70%;">
                                            <el-upload :action="ation"
                                                :limit="limitCountImg" class="dl-avatar-uploader-min square"
                                                :class="{ uoloadBtn: showBtnDealImg, disUoloadBtn: noneBtnImg }"
                                                :on-change="onChangeUpload" list-type="picture-card" :file-list="fileList"
                                                :headers="headers" :data="FormData" :on-preview="handlePreview"
                                                accept="image/jpeg,image/png,image/jpg" :on-exceed="handleExceedCover"
                                                :before-upload="beforeImageUpload" :on-remove="coverFileRemove"
                                                :on-success="coverFileSuccess">
                                                <div style="width:100%;height:100%;"> <img
                                                        src="../../assets/PersonalCenter/collect/uploading.png" alt=""
                                                        style="width:100%;height:100%;"> </div>
                                            </el-upload>
                                            <el-dialog v-model="dialogVisible">
                                                <img w-full class="image onsite-img" :src="dialogImageUrl" />
                                            </el-dialog>
                                        </div>
                                    </div>

                                </div>
                                <!-- 反馈建议 -->
                                <div class="PerBoxFive">
                                    <!-- 建议 -->
                                    <div class="PerSuggest">反馈建议/投诉︰</div>
                                    <!-- 内容 -->
                                    <div class="PerContent">
                                        <textarea name="content" id="content" placeholder="请输入" v-model="ruleForm.content"
                                            style="width:100%; height:100%;color: rgba(255, 255, 255, 1);background-color: rgba(255, 255, 255, 0);border:none;outline:0;"></textarea>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <!-- 我的点赞 -->
                        <!-- <div class="Creation"><p>我的点赞</p></div> -->
                        <!-- 我的收藏 -->
                        <!-- <div class="Like" @click=""><p>我的收藏</p></div> -->
                        <!-- 我要反馈 -->
                        <!-- <div class="Collection" @click="workTable"><p>我要反馈</p></div> -->
                        <!-- 我要反馈 -->
                        <!-- <div class="FeedBack" @click="workTable"><p>我要反馈</p></div> -->
                        <!-- 数字文创 -->
                        <!-- <div class="WenGen"><p>数字文创</p></div> -->
                        <!-- 畅玩古镇 -->
                        <!-- <div class="ChangPlay"><p>畅玩古镇</p></div> -->
                        <!-- 三维古镇 -->
                        <!-- <div class="Dimensional"><p>三维古镇</p></div> -->
                        <!-- 数字博物馆 -->
                        <!-- <div class="Museum"><p>数字博物馆</p></div> -->
                    </div>
                </div>
                <div v-if="routing" style="width: 83vw;height: 100%;">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import { log } from 'console';
// import { ref } from 'vue';
// import { Plus } from '@element-plus/icons-vue';

// import type { UploadProps, UploadUserFile } from 'element-plus'
export default {
    data() {
        return {
            ImgOne: false,
            particulars: {},
            Law: true,
            //图片上传
            fileUrl: [],
            url: [],
            dialogImageUrl: '',
            coversList: [],
            limitCountImg: 2,
            showBtnDealImg: true,
            noneBtnImg: false,
            dialogVisible: false,
            FormData: {   // 额外参数
                useType: 'feedback',
                imageFile: '',
            },
            ation:this.$Schttp +'/tools/fileUpload/imgUpload',
            headers: { Authorization: 'Bearer ' + this.$cookies.get("token") },
            // 反馈输入
            ruleForm: {
                name: '',
                tel: '',
            },
            rules: {
                tel: [
                    { required: true, message: "手机号不能为空", trigger: "blur" },
                    {
                        validator: function (rule, value, callback) {
                            if (/^1[34578]\d{9}$/.test(value) == false) {
                                callback(new Error("手机号格式错误"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur"
                    },
                    { min: 11, message: "手机号格式不正确", trigger: "blur" }
                ]
            },
            // 有数据的
            Bottom: false,
            BottomOne: true,
            // 没有数据的
            Data: true,
            Dataone: false,
            // // 鼠标移入隐藏和显示
            isShow: false,
            // ishow: false,
            // current: 0,// 当前操作按钮
            // 切换
            number: 0,
            // 渲染不同的背景颜色
            color: ["colorOne", "colorTwo", "colorThere"],
            mainPage: true,
            routing: false,
            // 用户信息
            UserList: '',
            // 公告列表
            NoticeList: [],
            // 点赞分类
            NavListOne: ["作品", "商品"],
            CountTher: 0,
            BtnnumTher: 0,
            ActiveTher: 0,
            // 收藏分类
            NavListTwo: ["作品", "商品"],
            CountFour: 0,
            BtnnumFour: 0,
            ActiveFour: 0,
            GivOne: [],
            // 分类
            active: 0,
            NavList: ["我的点赞", "我的收藏", "我要反馈"],
            count: 0,
            btnnum: 0,
            // 点赞
            loading: true, //loading
            ListTwo: [],
            FormOne: {
                // 每页条数
                pageSize: 10,
                // 页码
                pageNum: 1,
            },
            ObjectTypeOne:1,
            //   ObjectTypeOne:'',
            TotalOne: null,
            total: null,
            ListOne: [],
            Form: {
                // 每页条数
                pageSize: 10,
                // 页码
                pageNum: 1,
            },
            objectType: 1,
            //  测试
            detailed: '',
            details:{},
        }
    },
    provide() {
    return {
      fatherMethod: this.fatherMethodHandle,
    };
},
    created() {
        this.GetUserList()
        // this.scroll()
        this.GiveList()
        this.CollectListOne()
        this.aaa()
    },
    method() {

        // 点赞
        this.$refs.personDom.addEventListener('scroll', this.handleScroll, true);
        // 收藏
        this.$refs.PersonDomOne.addEventListener('scroll', this.HandleScrollOne, true);
    },
    destroyed() {

        this.$refs.PersonDomOne.addEventListener('scroll', this.HandleScrollOne, true);
        //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题 
        this.$refs.personDom.addEventListener('scroll', this.handleScroll, true);
        //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题 
    },

    methods: {
       
        DjFan(){
            this.$router.push("/");
        },
        //   收藏列表
        CollectListOne() {
            var axios = require("axios");
            const data = {
                pageSize: this.FormOne.pageSize,
                pageNum: this.FormOne.pageNum,
                objectType: this.ObjectTypeOne,
            };
            var config = {
                method: "post",
                url: this.$Schttp + "/vtp/app/customerCollection/page",
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config).then((res) => {

                if (res.data.code == 200) {
                    // const GivOne = res.data.rows;
                    // this.ListTwo.push(...GivOne);
                    this.ListTwo = res.data.rows;
                    this.TotalOne = res.data.total;
                    if (this.ListTwo == '') {
                        this.BottomOne = false;
                        this.DataOne = true;
                    }
                    if (this.ListTwo != '') {
                        this.BottomOne = true;
                        this.DataOne = false;
                    }
                    // if (this.ListTwo == ''&& this.ObjectTypeOne == 2) {
                    //     this.BottomOne = false;
                    //     this.DataOne = true;
                    // }

                }
            });
        },
        HandleScrollOne(e) {
            let h = e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight
            if (h > -10) {

                if (this.FormOne.pageNum * this.FormOne.pageSize >= this.TotalOne) {
                } else {
                    this.FormOne.pageNum++
                    this.CollectListOne()
                }
            }
        },
      // 收藏分页
    ChangeFix(index) {
        if (index == 0) {
            // this.pageNum.pageNum = 1
            this.ObjectTypeOne = 1;
            // if(this.ListTwo == ''){
                
            //     this.BottomOne = false;
            //     this.DataOne = true;
            //     console.log('收藏',this.BottomOne);
            //     console.log('收藏',this.DataOne);
            // }
        } 
        if (index == 1) {
            this.ObjectTypeOne = 2;
            // if(this.ListTwo == ''){
            //     this.BottomOne = false;
            //     this.DataOne = true;
            //     console.log('收藏',this.BottomOne);
            //     console.log('收藏',this.DataOne);
            // }
        }
        // this.ListTwo = []
        this.CollectListOne()
    },
         // 收藏分类
    ChangeFour(index) {
        this.CountFour = index;
        this.BtnnumFour = index;
        this.ActiveFour = index;
    },
   
        // 点赞请求接口
        GiveList() {
            var axios = require("axios");
            const data = {
                pageSize: this.Form.pageSize,
                pageNum: this.Form.pageNum,
                objectType: this.objectType,
            };
            var config = {
                method: "post",
                url: this.$Schttp + "/vtp/app/customerLikes/page",
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data: data,
            };

            axios(config).then((res) => {

                if (res.data.code == 200) {
                    // const giv = res.data.rows;
                    // this.ListOne.push(...giv);
                    this.ListOne = res.data.rows;
                    this.total = res.data.total;
                    if (this.ListOne == '') {
                        this.Bottom = false;
                        this.Data = true;
                    }
                    if (this.ListOne != '') {
                        this.Bottom = true;
                        this.Data = false;
                    }
                }
            });
        },
        handleScroll(e) {
            let h = e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight
            if (h > -10) {

                if (this.Form.pageNum * this.Form.pageSize >= this.total) {
                } else {
                    this.Form.pageNum++
                    this.GiveList()
                }
            }
        },
            // 点赞分类切换
    ChangeFive(index) {
        if (index == 0) {
            // this.Form.pageNum = 1;
            // console.log(this.ListOne);
            this.objectType = 1;
           
        }  
        if (index == 1) {
            // this.Form.pageNum = 1;
            this.objectType = 2;
           
        }
        // this.ListOne = []
        this.GiveList()
    },
        // 跳转铜官古镇
        GoTGAncientTown() {
            window.open('https://720yun.com/vr/a3025xikxen');
        },
        // 跳转靖港古镇
        GoJGAncientTown() {
            window.open('https://www.720yun.com/vr/aa826xi8ces');
        },
        // 跳转新康古镇
        GoXKAncientTown() {
            window.open('https://720yun.com/vr/e0b2dxikxeg');
        },
        // 跳转书堂山古镇
        GoSTSAncientTown() {
            window.open('https://720yun.com/vr/78521xikxlr');
        },
        // 跳转乔口古镇
        GoQKAncientTown() {
            window.open('https://720yun.com/vr/47120xikxl6');
        },
        // 切换登陆+退出登陆
        GoLogin() {
            this.$router.push("/login");
            this.$cookies.set("token", '',)
        },
        personalInformation() {
            this.mainPage = false;
            this.$router.push("/personalCenter/personalInformation");
            this.routing = true;
            this.details.deviceId = 0;
            $('.personalInformation ').css({ 'background-color': '#ECECEC', 'color': 'rgba(132, 43, 43, 1)' })
            $('.personalInformation').siblings().css({ 'background-color': '', 'color': 'rgba(255, 255, 255, 1)' })
            $('.home').css({ 'background-color': 'rgba(255, 255, 255, 0)', 'color': 'rgba(255, 255, 255, 1)' })
            // $('.PerImg').attr('src', require('../../assets/PersonalCenter/collect/personage.png'));
            // $('.home img').attr('src', require('../../assets/PersonalCenter/icon/HouseOne.png'));
            // $('.myOrder img').attr('src', require('../../assets/PersonalCenter/icon/order.png'));
            // $('.shoppingCart img').attr('src', require('../../assets/PersonalCenter/icon/consume.png'));
            // //  $('.paymentHistory img').attr('src', require('../../assets/PersonalCenter/PaymentHistory.png'));
            // $('.collect img').attr('src', require('../../assets/PersonalCenter/collect/collect.png'));
            // $('.colRest img').attr('src', require('../../assets/PersonalCenter/collect/colRest.png'));
            // $('.myAssessment img').attr('src', require('../../assets/PersonalCenter/icon/evaluate.png'));

        },
        myOrder() {
            this.mainPage = false;
            this.$router.push("/personalCenter/myOrder");
            this.routing = true;
            this.details.deviceId = 0;
            $('.myOrder').css({ 'background-color': '#ECECEC', 'color': 'rgba(132, 43, 43, 1)' })
            $('.myOrder').siblings().css({ 'background-color': '', 'color': 'rgba(255, 255, 255, 1)' })
            $('.home').css({ 'background-color': 'rgba(255, 255, 255, 0)', 'color': 'rgba(255, 255, 255, 1)' })
            // $('.PerImg').attr('src', require('../../assets/PersonalCenter/icon/resource.png'));
            // $('.home img').attr('src', require('../../assets/PersonalCenter/icon/HouseOne.png'));
            // $('.myOrder img').attr('src', require('../../assets/PersonalCenter/icon/OrderOne.png'));
            // $('.shoppingCart img').attr('src', require('../../assets/PersonalCenter/icon/consume.png'));
            // //  $('.paymentHistory img').attr('src', require('../../assets/PersonalCenter/PaymentHistory.png'));
            // $('.myAssessment img').attr('src', require('../../assets/PersonalCenter/icon/evaluate.png'));
            // $('.collect img').attr('src', require('../../assets/PersonalCenter/collect/collect.png'));
            // $('.colRest img').attr('src', require('../../assets/PersonalCenter/collect/colRest.png'));

        },
        // shoppingCart() {
        //     this.mainPage = false;
        //     this.$router.push("/personalCenter/shoppingCart");
        //     this.routing = true;
        //     $('.shoppingCart').css({ 'background-color': '#ECECEC', 'color': 'rgba(132, 43, 43, 1)' })
        //     $('.shoppingCart').siblings().css({ 'background-color': '', 'color': 'rgba(255, 255, 255, 1)' })
        //     $('.home').css({ 'background-color': 'rgba(255, 255, 255, 0)', 'color': 'rgba(255, 255, 255, 1)' })
        //     $('.PerImg').attr('src', require('../../assets/PersonalCenter/icon/resource.png'));
        //     $('.home img').attr('src', require('../../assets/PersonalCenter/icon/HouseOne.png'));
        //     $('.myOrder img').attr('src', require('../../assets/PersonalCenter/icon/order.png'));
        //     $('.shoppingCart img').attr('src', require('../../assets/PersonalCenter/icon/ConsumeOne.png'));
        //     //  $('.paymentHistory img').attr('src', require('../../assets/PersonalCenter/PaymentHistory.png'));
        //     $('.myAssessment img').attr('src', require('../../assets/PersonalCenter/icon/evaluate.png'));
        //     $('.collect img').attr('src', require('../../assets/PersonalCenter/collect/collect.png'));
        //     $('.colRest img').attr('src', require('../../assets/PersonalCenter/collect/colRest.png'));
        // },
        myAssessment() {
            this.mainPage = false;
            this.$router.push("/personalCenter/myAssessment");
            this.routing = true;
            this.details.deviceId = 0;
            $('.myAssessment').css({ 'background-color': '#ECECEC', 'color': 'rgba(132, 43, 43, 1)' })
            $('.myAssessment').siblings().css({ 'background-color': '', 'color': 'rgba(255, 255, 255, 1)' })
            $('.home').css({ 'background-color': 'rgba(255, 255, 255, 0)', 'color': 'rgba(255, 255, 255, 1)' })
            // $('.PerImg').attr('src', require('../../assets/PersonalCenter/icon/resource.png'));
            // $('.home img').attr('src', require('../../assets/PersonalCenter/icon/HouseOne.png'));
            // $('.myOrder img').attr('src', require('../../assets/PersonalCenter/icon/order.png'));
            // $('.shoppingCart img').attr('src', require('../../assets/PersonalCenter/icon/consume.png'));
            // //  $('.paymentHistory img').attr('src', require('../../assets/PersonalCenter/PaymentHistory.png'));
            // $('.myAssessment img').attr('src', require('../../assets/PersonalCenter/icon/EvaluateOne.png'));
            // $('.collect img').attr('src', require('../../assets/PersonalCenter/collect/collect.png'));
            // $('.colRest img').attr('src', require('../../assets/PersonalCenter/collect/colRest.png'));
        },
        workTable() {
            this.mainPage = false;
            this.$router.push("/personalCenter/workTable");
            this.routing = true;
        },
        home() {
            this.routing = false;
            this.mainPage = true;
            this.details.deviceId = 0;
            $('.home').css({ 'background-color': '#ECECEC', 'color': '#842B2B' })
            $('.home').siblings().css({ 'background-color': '', 'color': 'rgba(255, 255, 255, 1)' })
            // $('.PerImg').attr('src', require('../../assets/PersonalCenter/icon/resource.png'));
            // $('.home img').attr('src', require('../../assets/PersonalCenter/collect/house.png'));
            // $('.myOrder img').attr('src', require('../../assets/PersonalCenter/icon/order.png'));
            // $('.shoppingCart img').attr('src', require('../../assets/PersonalCenter/icon/consume.png'));
            // //  $('.paymentHistory img').attr('src', require('../../assets/PersonalCenter/PaymentHistory.png'));
            // $('.myAssessment img').attr('src', require('../../assets/PersonalCenter/icon/evaluate.png'));
            // $('.collect img').attr('src', require('../../assets/PersonalCenter/collect/collect.png'));
            // $('.colRest img').attr('src', require('../../assets/PersonalCenter/collect/colRest.png'));
        },
        // 征集管理
        // collect() {
        //     this.mainPage = false;
        //     this.$router.push("/personalCenter/collect");
        //     this.routing = true;
        //     $('.collect').css({ 'background-color': '#ECECEC', 'color': 'rgba(132, 43, 43, 1)' })
        //     $('.collect').siblings().css({ 'background-color': '', 'color': 'rgba(255, 255, 255, 1)' })
        //     $('.home').css({ 'background-color': 'rgba(255, 255, 255, 0)', 'color': 'rgba(255, 255, 255, 1)' })
        //     $('.PerImg').attr('src', require('../../assets/PersonalCenter/icon/resource.png'));
        //     $('.home img').attr('src', require('../../assets/PersonalCenter/icon/HouseOne.png'));
        //     $('.myOrder img').attr('src', require('../../assets/PersonalCenter/icon/order.png'));
        //     $('.shoppingCart img').attr('src', require('../../assets/PersonalCenter/icon/consume.png'));
        //     $('.myAssessment img').attr('src', require('../../assets/PersonalCenter/icon/evaluate.png'));
        //     $('.collect img').attr('src', require('../../assets/PersonalCenter/icon/CollectOne.png'));
        //     $('.colRest img').attr('src', require('../../assets/PersonalCenter/collect/colRest.png'));
        // },
        // 其他服务
        colRest() {
            this.mainPage = false;
            this.$router.push("/personalCenter/colRest");
            this.routing = true;
            $('.colRest').css({ 'background-color': '#ECECEC', 'color': 'rgba(132, 43, 43, 1)' })
            $('.colRest').siblings().css({ 'background-color': '', 'color': 'rgba(255, 255, 255, 1)' })
            $('.home').css({ 'background-color': 'rgba(255, 255, 255, 0)', 'color': 'rgba(255, 255, 255, 1)' })
            // $('.PerImg').attr('src', require('../../assets/PersonalCenter/icon/resource.png'));
            // $('.home img').attr('src', require('../../assets/PersonalCenter/icon/HouseOne.png'));
            // $('.myOrder img').attr('src', require('../../assets/PersonalCenter/icon/order.png'));
            // $('.shoppingCart img').attr('src', require('../../assets/PersonalCenter/icon/consume.png'));
            // //  $('.paymentHistory img').attr('src', require('../../assets/PersonalCenter/PaymentHistory.png'));
            // $('.myAssessment img').attr('src', require('../../assets/PersonalCenter/icon/evaluate.png'));
            // $('.collect img').attr('src', require('../../assets/PersonalCenter/collect/collect.png'));
            // $('.colRest img').attr('src', require('../../assets/PersonalCenter/icon/ColRestOne.png'));
        },
        change(index) {
            this.active = index;
            this.count = index;
            this.btnnum = index;
        },
        ChangeTher(index) {
            this.CountTher = index;
            this.BtnnumTher = index;
            this.ActiveTher = index;
        },
        aaa() {
            this.details = this.$route.query;
            if (this.details.deviceId == 1) {
                this.colRest()

            }
        },
        // 获取用户信息UserList
        GetUserList() {
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/customer/getUserInfo',
                headers: {
                Authorization: "Bearer " + this.$cookies.get("token"),
                "Content-Type": "application/json",
            },
            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        if (res.data.code == 401) {
                            this.$router.push('/login')
                        } else {
                            this.UserList = res.data.data
                            if (this.UserList.iconPath != null) {
                                this.ImgOne = true
                            }
                        }
                    }else{
                        this.$router.push('/login')
                    }
                })
        },
        fatherMethodHandle() {
      this.GetUserList()
    },
        // 图片上传
        onChangeUpload(file, fileList) {
            // this.coversList = []
            this.FormData.imageFile = file.raw
            this.noneBtnImg = fileList.length >= this.limitCountImg;
        },
        beforeImageUpload(rawFile) {
            if (rawFile.size / 1024 / 1024 > 2) {
                this.$message.error("单张图片大小不能超过2MB!");
                return false;
            }
            return true;
        },
        coverFileSuccess(response, file, fileList) {
            if (response.code === 200) {
                let obj = {};
                obj.name = file.name;
                obj.url = response.data;
                this.coversList.push(obj);
                this.fileUrl.push(obj.url.fileUrl)
            } else {
                this.$message.error(`图片${response.msg}上传失败`)
            }
        },
        coverFileRemove(file, fileList) {
            this.coversList.forEach((item, index) => {
                if (file.name == item.name) {
                    this.coversList.splice(index, 1);
                }
            });
            this.noneBtnImg = fileList.length >= this.limitCountImg;
        },
        handleExceedCover(files, fileList) {
            this.$message.error({
                message: `上传图片数量超出限制！`,
                type: "error",
            });
        },
        handlePreview(uploadFile) {
            this.dialogImageUrl = uploadFile.url;
            this.dialogVisible = true;

        },

        // 鼠标移入显示
        showDialog(e) {
            var BacImg = e.currentTarget.firstElementChild;
            $(BacImg).show()
        },
        // 鼠标移出隐藏
        hideDialog(e) {
            var BacImg = e.currentTarget.firstElementChild;
            $(BacImg).hide()
        },
    },
   
    //点击提交反馈
    feedback() {
        var axios = require('axios');
        var config = {
            method: 'post',
            url: this.$Schttp + '/vtp/app/feedback/save',
            headers: {
                Authorization: "Bearer " + this.$cookies.get("token"),
                "Content-Type": "application/json",
            },
            data: {
                mobile: this.ruleForm.tel,
                realName: this.ruleForm.name,
                content: this.ruleForm.content,
                imgs: this.fileUrl,
            },
        };
        axios(config)
            .then((res) => {
                if (res.data.code == 200) {
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    })
                } else {
                    this.$message({
                        message: '提交失败',
                        type: 'error'
                    })
                }
            })
            .catch(function (error) {
            });
    },
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
//     overflow-y: hidden; /* 隐藏垂直滚动条 */
//   overflow-x: hidden; /* 隐藏水平滚动条 */
}

.BigBox {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/PersonalCenter/PersonalCenterBG.png);
    background-size: 100% 100%;
}

.PersonalCenter {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .Header {
        width: 100%;
        height: 10%;
        min-height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .HeaderMessage {
            align-items: center;
            display: flex;
            margin-right: 5vw;
            margin-top: 2vh;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .Content {
        width: 99%;
        height: 90%;
        // min-height: 400px;
        display: flex;
        color: white;

        .NavigationBar {
            width: 17vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: rgba(24, 40, 121, 0.20);

            .HeadPortrait {
                width: 8vw;
                height: 16vh;
                border-radius: 50%;
                margin-top: 4vh;

            }

            .FunctionBar {
                width: 100%;

                height: 45%;

                display: flex;
                flex-direction: column;
                margin-top: 6vh;

                .home {
                    width: 100%;
                    background-color: rgba(236, 236, 236, 0.5);
                    color: rgba(132, 43, 43, 1);
                }
                .HomeOne{
                    background-color: rgba(236, 236, 236, 0);
                    color: #FFFFFF;
                }

                div {
                    width: 100%;
                    flex: 1;
                    display: flex;
                    align-items: center;



                    img {
                        width: 1.5vw;
                        height: 3vh;
                        margin-left: 4vw;
                        margin-right: 1vw;
                    }

                    p {
                        font-family: Source Han Sans SC-Medium;
                        // color: #626262;
                        font-size: 1vw;
                    }
                }

                div:hover {

                    background-color: #999999;
                    cursor: pointer;
                }

            }
            .ColRestOne{
                background-color:rgba(255, 255, 255, 1);
                p{
                    color:#842b2b;
                }
            }
            .Buttons {
                width: 100%;
                height: 15vh;
                margin-top: 3vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;

                div {
                    width: 6vw;
                    min-width: 80px;
                    height: 4vh;
                    min-height: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 16px;
                    background-color: #E47878;
                    color: #FFFFFF;
                    font-size: 0.8vw;
                }

                div:hover {
                    cursor: pointer;
                }
            }
        }

        .Show {
            width: 82vw;
            height: 100%;
            display: flex;
            flex-direction: column;

            .ShowTop {
                width: 95%;
                height: 24%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-top: 4vh;
                margin-left: 2vh;

                .AncientTown {
                    height: 100%;
                    width: 100%;
                    background-image: url("../../assets/PersonalCenter/ancientTown.png");
                    background-size: 100% 100%;
                    display: flex;
                    border-radius: 1vh;

                    // flex-wrap: wrap;
                    div {
                        display: flex;
                        flex: 33.33%;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 90%;
                            height: 75%;
                            cursor: pointer;
                        }

                        img:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }

            .ShowBottom {
                width: 100%;
                // min-height:60%;
                height: 65%;
                display: flex;
                margin-top: 3vh;

                .Law-Tab {
                    width: 5%;
                    display: flex;
                    align-items: center;

                    .Law-Tab-Nav {
                        width: 100%;
                        font-size: 2vh;
                        margin-top: 4vh;

                        .li {
                            width: 70%;
                            height: 13vh;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 0.5vh;
                            cursor: pointer;

                            .Zt {
                                width: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                            }
                        }
                    }

                    .btna {
                        width: 90% !important;
                    }

                    .colorOne {
                        background: rgba(94, 29, 29, 0.7);
                    }

                    .colorTwo {
                        background: rgba(46, 18, 45, 0.7);
                    }

                    .colorThere {
                        background: rgba(50, 71, 58, 0.7);
                    }

                    .actived {
                        width: 70% !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }

                .end-cont {
                    display: none;
                    width: 100%;
                    height: 100%;

                    .Law-TabOne {
                        width: 15%;

                        .Law-Tab-NavOne {
                            width: 100%;
                            display: flex;
                            font-size: 2vh;

                            .LiOne {
                                margin-left: 2vh;
                                width: 19%;
                                height: 20%;
                                font-size: 2vh;
                                font-weight: bold;
                                color: rgba(221, 221, 221, 1);
                                background-color: rgba(255, 255, 255, 0);
                                cursor: pointer;
                            }
                        }

                        .ActivedTher {
                            color: rgba(255, 255, 255, 1);
                            border-bottom: 2px solid rgba(255, 255, 255, 1);

                        }

                    }

                    .End-contTher {
                        width: 98%;
                        height: 86%;

                        .PerBox {
                            width: 100%;
                            height: 100%;

                            .PerBoxOne {
                                width: 100%;
                                height: 48vh;
                                margin-top: 2.5vh;

                                display: flex;
                                flex-wrap: wrap;
                                overflow: hidden;
                                overflow-y: scroll;


                                .PerPicture {
                                    width: 100%;
                                    height: 75%;
                                    border: 1px dashed rgba(221, 221, 221, 1);


                                    .PerPictureOne {
                                        width: 100%;
                                        height: 100%;
                                        // background: url(../../assets/PersonalCenter/photograph.png);
                                        background-size: 100% 100%;
                                        display: flex;

                                        justify-content: flex-end;

                                        .PerDelete {
                                            width: 2.8vh;
                                            height: 3.2vh;
                                            background-color: rgba(205, 205, 205, 0.7);
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                        }
                                    }
                                }

                            }

                            /* 整个滚动条 */
                            ::-webkit-scrollbar {
                                width: 0.3vw;
                                height: 0.1vh;
                                background-color: #64A2FF;;
                            }

                            /* 滚动条上的滚动滑块 */
                            ::-webkit-scrollbar-thumb {
                                height: 0.3vh;
                                background-color: #0F1755;
                                border-radius: 32px;
                            }
                        }
                    }

                    .DisTher {
                        display: block;
                    }

                    .Law-TabTwo {
                        width: 15%;

                        .Law-Tab-NavTwo {
                            width: 100%;
                            display: flex;
                            font-size: 2vh;
                            cursor: pointer;

                            .LiOne {
                                margin-left: 2vh;
                                width: 19%;
                                height: 20%;
                                font-size: 2vh;
                                font-weight: bold;
                                color: rgba(221, 221, 221, 1);
                                background-color: rgba(255, 255, 255, 0);

                            }
                        }

                        .ActivedFour {
                            color: rgba(255, 255, 255, 1);
                            border-bottom: 2px solid rgba(255, 255, 255, 1);
                        }

                    }

                    .End-contFour {
                        width: 98%;
                        height: 86%;

                        .PerBox {
                            width: 100%;
                            height: 100%;

                            .PerBoxOne {
                                width: 100%;
                                height: 48vh;
                                margin-top: 2.5vh;

                                display: flex;
                                flex-wrap: wrap;
                                overflow: hidden;
                                overflow-y: scroll;


                                .PerPicture {
                                    width: 100%;
                                    height: 75%;
                                    border: 1px dashed rgba(221, 221, 221, 1);

                                    .PerPictureOne {
                                        width: 100%;
                                        height: 100%;
                                        // background: url(../../assets/PersonalCenter/photograph.png);
                                        background-size: 100% 100%;
                                        display: flex;
                                        justify-content: flex-end;

                                        .PerDelete {
                                            width: 2.8vh;
                                            height: 3.2vh;
                                            background-color: rgba(205, 205, 205, 0.7);
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                        }
                                    }
                                }

                            }

                            /* 整个滚动条 */
                            ::-webkit-scrollbar {
                                width: 0.3vw;
                                height: 0.1vh;
                                background-color: #64A2FF;
                            }

                            /* 滚动条上的滚动滑块 */
                            ::-webkit-scrollbar-thumb {
                                height: 0.3vh;
                                background-color: #0F1755;
                                border-radius: 32px;
                            }
                        }
                    }

                    .ShowData {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        .ShowPicture {
                            width: 23%;
                            height:50%;
                            margin-left: -15vh;
                            margin-top: 12vh;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    .DisFour {
                        display: block;
                    }

                    .perNumber {
                        width: 96.5%;
                        height: 90%;
                    }

                    .perNumber::-webkit-scrollbar {
                        width: 1.8vh;
                        /*高宽分别对应横竖滚动条的尺寸*/
                        // height: 0px;
                    }

                    .perNumber::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        -webkit-box-shadow: inset 0 0 5px rgba(237, 11, 11, 0.2);
                        background-color: #64A2FF;
                    }

                    .perNumber #main::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                        border-radius: 0;
                        background-color: #0F1755;
                    }

                }

                .ShowData {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .ShowPicture {
                        width: 23%;
                        height: 43%;
                        margin-left: -15vh;
                        margin-top: 12vh;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .PerBoxTwo {
                    width: 96.5%;
                    height: 90%;

                    display: flex;
                    align-items: center;

                    .PerBoxTher {
                        width: 50%;
                        height: 90%;
                        // height: 80%;
                        // margin-top: 2.9vh;

                        .PerCons {
                            width: 100%;
                            height: 50%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .PerRor {
                                width: 100%;
                                height: 6vh;
                                border-bottom: 1px dashed rgba(255, 255, 255, 1);
                                display: flex;
                                align-items: center;
                                margin-top: 3vh;

                                .el-input /deep/ .el-input__inner {
                                    width: 100%;
                                    background-color: rgba(255, 255, 255, 0);
                                    color: rgba(255, 255, 255, 1);
                                    margin-top: 0.7vh;
                                    border: none;
                                }
                            }
                        }

                        .PerBoxFour {
                            width: 90%;
                            height: 45%;
                            margin-left: 2vh;
                            display: flex;
                            justify-content: space-between;

                            /deep/ .el-upload {
                                background-color: rgba(255, 255, 255, 0);
                                border: none;

                            }

                            ::v-deep .disUoloadBtn .el-upload--picture-card {
                                display: none;
                                /* 上传按钮隐藏 */

                            }

                            // /deep/ 后要加一个空格

                            /deep/ .el-dialog {
                                width: 70%;
                                height: 70%;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                .image {
                                    width: 20vw;
                                }
                            }

                            .PerUploading {
                                width: 70%;
                                height: 100%;

                                .upload-demo /deep/ .el-upload--picture-card {
                                    height: 100%;
                                    width: 9vw;
                                    ;
                                    background-color: rgba(255, 255, 255, 0);

                                }
                            }
                        }
                    }

                    .PerBoxFive {
                        width: 45%;
                        height: 90%;
                        margin-left: 6.8vh;
                        background-image: url(../../assets/PersonalCenter/BieJin.png);
                        background-size: 100% 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .PerSuggest {
                            width: 90%;
                            margin-top: 1vh;
                        }

                        .PerContent {
                            width: 90%;
                            height: 75%;
                            margin-top: 2vh;

                            // overflow: hidden scroll;
                            #content::-webkit-input-placeholder {
                                color: rgba(255, 255, 255, 1);
                            }
                        }
                    }

                    /* 整个滚动条 */
                    ::-webkit-scrollbar {
                        width: 0.3vw;
                        height: 0.1vh;
                        background-color: #64A2FF;
                    }

                    /* 滚动条上的滚动滑块 */
                    ::-webkit-scrollbar-thumb {
                        height: 0.3vh;
                        background-color: #0F1755;
                        border-radius: 32px;
                    }
                }

                .dis {
                    display: block;
                }
            }

        }
    }
}</style>